import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/Layout";
import MainPost from "../components/MainPost";
import SEO from "../components/Seo";
import Breadcrumb from "../components/Breadcrumb";

function FaqTemplate({ location, data: { mdx: faq } }) {
  if (!faq) {
    return <p>No Post Found? This should be a 404</p>;
  }

  const FAQstructuredJSON = `
    {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": {
            "@type": "Question",
            "name": "${faq.frontmatter.title}",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "${faq.frontmatter.answer}"
            }
        }
    }
`;

  const breadCrumbLevels = {
    Home: `/${faq.frontmatter.language}`,
    FAQ: `/${faq.frontmatter.language}/faq`,
    [faq.frontmatter.title]: `/${faq.frontmatter.language}/${faq.frontmatter.slug}`
  };

  const alternateLangs = Object.entries(faq.frontmatter.hreflang).map(([lang, href]) => ({
    hreflang: lang === 'x_default' ? 'x-default' : lang,
    href: href.startsWith('/') ? href : `/${href}`
  }));

  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title={faq.frontmatter.titletag}
          description={faq.frontmatter.description}
          lang={faq.frontmatter.language}
          alternateLangs={alternateLangs}
        />
        <script type="application/ld+json">{FAQstructuredJSON}</script>
        <MainPost>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <MDXRenderer
          //   timeToRead={post.timeToRead}
          //   tags={post.frontmatter.tags && post.frontmatter.tags.split(", ")}
          //   date={post.frontmatter.update}
          >
            {faq.body}
          </MDXRenderer>
        </MainPost>
      </React.Fragment>
    </Layout>
  );
}

export default FaqTemplate;

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    site {
      siteMetadata {
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }, frontmatter: { language: { eq: $language } }) {
      fields {
        relativePath
      }
      fileAbsolutePath
      excerpt
      body
      timeToRead
      id
      frontmatter {
        title
        answer
        slug
        hreflang {
          ... on HreflangObject {
            x_default
            da
            de
            en
            fi
            fr
            nl
            no
            se
          }
        }
        titletag
        language
        description
        tags
        update(formatString: "DD. MMMM YYYY")
        updateISO: update(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        publish(formatString: "DD. MMMM YYYY")
        publishISO: publish(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        image {
          id
          relativePath
        }
      }
    }
  }
`;
